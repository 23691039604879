.Join{
   display: flex;
   padding: 0 2rem; 
   align-items: center;
   justify-content: center;
   padding-bottom: 4rem;
}

.join-b-1{
    width: 37rem;
    height: 26rem;
    top: 1rem;
    left: 0rem;
    filter: blur(230px);
}

.join-b-2{
    width: 37rem;
    height: 26rem;
    top: 1rem;
    right: 0rem;
    filter: blur(230px);
}

.container{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 60rem;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    background-image: url('../../assets/background.png');
    background-size: cover;
    background-position: center;
    padding: 4rem;
    color: white;
    position: relative;
    gap: 2rem;
}

.container>:nth-child(3){
    font-size: 3rem;
    font-weight: bold;
}

.container>div>:nth-child(2){
    color: var(--orange);
}

.register {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.email-container {
    display: flex;
    gap: 3rem;
    position: relative;
    background-color: rgba(255, 198, 144, 0.8);
    border-radius: 10px;
    padding: 0.5rem 0.8rem;
    backdrop-filter: blur(2px);
}

.email-container > input {
    background-color: transparent;
    border: none;
    outline: none;
    color: black;
    font-size: 0.9rem;
}

::placeholder {
    color: black;
}

.btn-j {
    background-color: var(--orange);
    color: white;
    flex: 1;
}

@media screen and (max-width: 768px) {
    .Join{
        flex-direction: column;
        gap: 1rem;
        padding-bottom: 0;
    }

    .join-b-1{
        filter: blur(260px);
        height: 18rem;
    }

    .join-b-2{
        filter: blur(260px);
        height: 18rem;
    }

    .container{
        background-image: none;
    }

    .container>:nth-child(3){
        font-size: xx-large;
        text-align: center;
    }

    .container>:nth-child(4) {
        font-size: 14px;
        text-align: center;
    }
    
    .register{
        padding: 1rem;
    }

    .email-container{
        gap: 0.5rem;
    }

    .email-container > input{
        font-size: .8rem;
    }

    .btn-j{
        width: 5rem;
        height: 2.4rem;
        font-size: 12px;
    }
}
